<template>
    <section id="state2" class="app talisman-app wf-section bgMint">
      <div class="w-layout-blockcontainer main main-list w-container mint-confirmation">
        <ul role="list" class="talismans w-list-unstyled">
          <li class="talisman" v-for="(token, index) in filteredTokens" :key="index" :class="{'selected-border': token.selected}">
            <a :href="token.link" target="_blank" @click.prevent="tokenClicked(token)" class="link-block-2 w-inline-block-token">
              <img :src="token.image" loading="lazy" width="100" height="100" alt="" class="talisman-image">
              <div class="div-block-3">
                <h3 class="heading-3"> {{ toRoman(token.display_series) }} #{{ token.display_id }}</h3>
                <p class="paragraph-2">{{ token.description }}</p>
              </div>
              <p class="talisman-count">
                  <img class="talisman-check" :src="token.selected ? '/images/checked.svg' : '/images/unchecked.svg'" alt="" />
              </p>
            </a>
          </li>
        </ul>
        <div class="springboard">
          <!-- <ul role="list" class="elementals w-list-unstyled">
            <li class="elemental" v-for="elemental in elementals" :key="elemental.id">
              <a :href="elemental.link" target="_blank" class="w-inline-block"><img :src="elemental.image" loading="lazy" width="75" height="75" alt="" :class="{ 'elemental-image': true, 'not-earned': !hasTokenId(elemental.id) }"></a>
            </li>
          </ul> -->
          <button @click.stop="confirmClicked" class="button-2 w-button" :class="{'button-enabled': isAnyTokenSelected, 'button-disabled': !isAnyTokenSelected || isRevealing}" :disabled="!isAnyTokenSelected || isRevealing">
            <strong v-if="!isRevealing">Unlock Version</strong>
            <strong v-else>Unlock...</strong>
          </button>
          <div class="div-block-4">
            <p class="paragraph-3 error-text" v-if="errorText">
              {{ errorText }}
            </p>
            <p class="paragraph-3" v-else>
                {{ total_price }} $WAR
            </p>
          </div>

        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { useEligibilityStore } from '../store/eligibility';
  import { usePurchaseStore } from '../store/purchase';
  // import { computed, watch, ref } from 'vue';
  import { computed, ref } from 'vue';
  
  export default {
    name: 'TokenSelect',
    setup(props, {emit}){
      const eligibilityStore = useEligibilityStore()
      const purchaseStore = usePurchaseStore()
      const isRevealing = ref(false)
      const errorText = ref('')
      const userBalance = ref(purchaseStore.user_balance)
      // const price = ref(purchaseStore.price)
      const price = ref(purchaseStore.price)
      const tokenClicked = (tokenDetail) => {
        if (!tokenDetail.selected && (total_price.value + price.value) > userBalance.value){
          errorText.value = `Unlocking additional tokens would exceed your $WAR balance of ${userBalance.value}`
          return
        }
        errorText.value = ''
        tokenDetail.selected = !tokenDetail.selected;
        purchaseStore.addOrRemoveRevealedToken(tokenDetail)
        // purchaseStore.addRevealedToken(tokenDetail)
      }
    const filteredTokens = computed(() => {
        return purchaseStore.getTokensForSelectedSeries
    });

    const isAnyTokenSelected = computed(() => {
      if(purchaseStore.getSelectedThisSession){
        return purchaseStore.getSelectedThisSession.length >= 1
      }
      return false
    });
    const total_price = computed(() => {
        return price.value * purchaseStore.getSelectedThisSession.length
    })

      const confirmClicked = async () => {
        isRevealing.value = true
        const eligibilityStore = useEligibilityStore()
        eligibilityStore.setUnlocking(true)
        emit('next', 'LoadingView')
        // const reveal_success = await revealStore.revealTokens()
        // isRevealing.value = false
        // if(reveal_success){
        //   emit('next', 'YourTokens')
        // } else {
        //   errorText.value = "Your error message here."
        //   setTimeout(() => {
        //     errorText.value = ''
        //   }, 10000);
        // }
      }
      const get_parsed_id = (id) => {
        return Number(id) + 1
      }
      const no_tokens = ref(purchaseStore.getWithoutVersion.length == 0)
      if(no_tokens.value){
        eligibilityStore.setEligible('no_talismans')
        // emit('terminate', true)

      }
  
      return {
        confirmClicked,
        tokens: purchaseStore.getWithoutVersion,
        tokenClicked,
        isAnyTokenSelected,
        isRevealing,
        get_parsed_id,
        errorText,
        total_price,
        filteredTokens
      }
    }
  }
  </script>
  <style scoped>
  .w-inline-block-token{
    display: flex;
    max-width: 100%;
  }
  .main.main-list.mint-confirmation{
    height: 100vh;
  }
  .link-block-2{
    gap: .5rem;
    padding-right: 1rem;
  }
  .button-2.w-button:disabled {
    background-color: #606060 !important;
    color: #0f0f0f !important;
  }
  .talisman {
    border: 1px solid transparent;
  }
  .selected-border{
    /* border-color: #cf0; */
  }
  .talisman-check{
    width: 24px;
    height: 24px;
  }
  .error-text{
    color:red;
  }
  </style>