export default {
    methods: {
        toRoman(input) {
            const roman = { M:1000, CM:900, D:500, CD:400, C:100, XC:90, L:50, XL:40, X:10, IX:9, V:5, IV:4, I:1 };
            let num, str = '';
        
            // Case 3: "Series X"
            if (typeof input === 'string' && input.startsWith("Series ")) {
                num = parseInt(input.split(" ")[1]);
                if (isNaN(num)) {
                    return input;  // Return the original string if parsing fails
                }
                str += input.split(" ")[0] + " "
            } 
            // Case 1 & 2: integer or string of integer
            else {
                num = parseInt(input);
                if (isNaN(num)) {
                    return input;  // Return the original input if parsing fails
                }
            }
        
            for (let i of Object.keys(roman)) {
                let q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }
          
            return str;
        },
        name_map(input){
            const mapping = {
                'series_1': 'Series I',
                'series_2': 'Series II'
            }
            return mapping[input]
        }
        
    }
  }