<template>
    <section id="thank-you" class="app bg2 wf-section">
        <div class="w-layout-blockcontainer container-8 w-container"><img src="/images/yours.svg" loading="lazy" width="851.5" alt="" class="image-5">
        <div class="text-block-6 yellow">You unlocked {{ versionCount }} {{ name_map(revealedVersions) }} Warriors!</div>
        <ul role="list" class="list w-list-unstyled">
            <li v-for="(revealed, index) in versionTokens" :key="index" id="w-node-a9843196-9523-b2ff-d6c8-d69f6e98054e-7390a122" class="series-card">
            <a :href="revealed.url" class="series-card-container w-inline-block"><img :src="revealed.image " loading="lazy" alt="">
                <div class="div-block-19">
                <div class="text-block-7">{{ name_map(revealedVersions) }} #{{ revealed.token_id }}</div>
                <div class="text-block-7">{{ revealed.rarity }}</div>
                </div>
            </a>
            </li>
        </ul>
        <div class="div-block-20">
            <div v-for="(series, key) in versionTokens" :key="key">
                <h3 class="heading-7">{{ name_map(series.name) }}</h3>
                <ul role="list" class="list-2 w-list-unstyled">
                    <li v-for="(token, token_key) in series.tokens" :key="token_key">
                        <a :href="token.url" class="series-card-container w-inline-block" :class="{revealed: versionTokenIDs.includes(token.token_id)}">
                        <img :src="token.image" loading="lazy" alt="">
                    </a>
                    </li>
                </ul>
            </div>
        </div>
        </div>
    </section>
  </template>
  
  <script>
//   import { useEligibilityStore } from '../store/eligibility';
// import { computed, watch, ref } from 'vue';
//   import { computed, ref } from 'vue';
  import { computed } from 'vue'
import { usePurchaseStore } from '../store/purchase'
  
  export default {
    name: 'YourVersions',
    setup(){
      const purchaseStore = usePurchaseStore()
      const revealedVersions = computed(() => {
        return purchaseStore.selected_series
      })
      const versionTokenIDs = computed(() => {
        return purchaseStore.getSelectedThisSession.map(item => item.token_id)
      })
      const versionCount = computed(() => {
        return purchaseStore.getSelectedThisSession.length
      })
      const versionTokens = computed(() => {
        const version_tokens = purchaseStore.revealed_tokens
        return version_tokens
      })
      const get_parsed_id = (id) => {
        return Number(id) + 1
      }

      const seriesTokens = computed(() => {
        const series_tokens = JSON.parse(JSON.stringify(purchaseStore.series));
        for (let key in series_tokens) {
            if (series_tokens[key].tokens) {
                series_tokens[key].tokens = series_tokens[key].tokens.filter(token => token.revealed === true);
                
                series_tokens[key].tokens.sort((a, b) => {
                    const aIsRevealed = versionTokenIDs.value.includes(a.token_id);
                    const bIsRevealed = versionTokenIDs.value.includes(b.token_id);
                    return bIsRevealed - aIsRevealed;
                });
                
                if (series_tokens[key].tokens.length === 0) {
                    delete series_tokens[key];
                }
            } else {
                delete series_tokens[key];
            }
        }
        return series_tokens;
    });
      

      return {
        revealedVersions,
        versionCount,
        versionTokens,
        seriesTokens,
        versionTokenIDs,
        get_parsed_id
      }
    }
  }
  </script>
  <style scoped>
  .main.main-list.mint-confirmation{
    height: 100vh;
  }
  .link-block-2{
    gap: .5rem;
    padding-right: 1rem;
  }
  .button-2.w-button:disabled {
    background-color: #606060 !important;
    color: #0f0f0f !important;
  }
  </style>