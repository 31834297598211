<template>
    <section id="thank-you" class="app bg2 wf-section">
        <div class="w-layout-blockcontainer container-8 w-container"><img src="/images/choose.svg" loading="lazy" alt="" class="image-5">
        <div class="text-block-6 yellow">SELECT SERIES</div>
        <ul role="list" class="list w-list-unstyled">
        <li v-for="(version, index) in releasedVersions" :key="index" class="series-card">
            <a @click="nav_selected(version.key)" class="series-card-container w-inline-block">
            <h2 class="heading-6">SERIES {{ version.name }}</h2>
            <img :src="version.image" loading="lazy" alt="">
            <div class="div-block-19">
                <div class="text-block-7">{{ getWithVersion(version.key) }} Unlocked</div>
                <div class="text-block-7">{{ getWithoutVersion(version.key) }} Locked</div>
            </div>
            </a>
        </li>
        </ul>
        </div>
    </section>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import { usePurchaseStore } from '../store/purchase'
  import { SeriesData } from '@/assets/data/series.js'
  export default {
    name: 'PurchaseSelect',
    setup(props, {emit}){
      const version_data = ref(SeriesData)
      const purchaseStore = usePurchaseStore()
      const releasedVersions = computed(() => {
        return Object.keys(version_data.value)
        .filter(key => version_data.value[key].released)
        .map(key => ({name: key, ...version_data.value[key]}))
      })

      const nav_selected = (version_key) => {
        purchaseStore.setSelectedSeries(version_key)
        emit('next', 'TokenSelect')
      }
      const getWithVersion = (version_key) => {
        const with_version = purchaseStore.getWithVersionBySeries(version_key)
        return with_version.length
      }
      const getWithoutVersion = (version_key) => {
        const without_version = purchaseStore.getWithoutVersionBySeries(version_key)
        return without_version.length
      }
  
      return {
        version_data,
        getWithVersion,
        getWithoutVersion,
        releasedVersions,
        nav_selected
      }
    }
  }
  </script>
  
    <style>
    .counter{
      display: flex;
      flex-direction: column;
      align-items: center;
  
    }
    /* @media (max-width: 768px) {
    .counter {
      margin-top: 1rem;
    }
  } */
    .controls{
      font-size: 2rem;
      background: none;
      border: none;
      border-radius: 50%;
      height: 50px;
      width: 50px;
    }
      .controls:hover{
      background: #ccff0010;
      cursor: pointer;
      color: #cf0;
    }
    .price-symbol{
      color: #cf0
    }
    #demoInput{
      font-size: 4rem;
      text-align: center;
      padding: 0;
      border: none;
      margin-bottom: 1rem;
      background: none;
    }
    /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .button-2.w-button:disabled {
      background-color: #606060 !important;
      color: #0f0f0f !important;
    }
  </style>
  