<template>
  <div>
    <section v-if="!eligibilityStore.skip" id="checking" class="app app-black talisman-loader wf-section">
      <div class="w-layout-blockcontainer main w-container"><img src="@/assets/images/talismans.gif" loading="lazy" width="125" height="125" alt="" class="loader talisman">
        <a id="signIn" href="https://awrd.gg" target="_blank" class="signin checking w-inline-block">
          <div class="sign-in-button loading">CHECKING TALISMANS</div>
        </a>
      </div>
    </section>
    <section v-else-if="eligibilityStore.revealing" id="loading" class="app app-black wf-section">
      <div class="w-layout-blockcontainer main w-container"><img src="@/assets/images/loader2.gif" loading="lazy" width="125" height="125" alt="" class="loader cube">
        <a id="signIn" href="https://awrd.gg" target="_blank" class="signin checking w-inline-block">
          <div class="sign-in-button loading">Revealing {{ revealing_total }} Warriors</div>
        </a>
      </div>
    </section>
    <section v-else id="loading" class="app app-black wf-section">
      <div class="w-layout-blockcontainer main w-container"><img src="@/assets/images/loader2.gif" loading="lazy" width="125" height="125" alt="" class="loader cube">
        <a id="signIn" href="https://awrd.gg" target="_blank" class="signin checking w-inline-block">
          <div class="sign-in-button loading">Verifying assets</div>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useEligibilityStore } from '../store/eligibility'
import { useRevealStore } from '../store/reveal'
import { usePurchaseStore } from '../store/purchase'
import { useUserStore } from '../store/user'
import { useRouter } from 'vue-router'

export default {
  name: 'LoadingView',
  setup(props, { emit }){
    const router = useRouter()
    const checking = ref(true);
    const eligibilityStore = useEligibilityStore()
    const userStore = useUserStore()

    const revealing_total = ref(0)

    const handleDetails = async () => {
      const revealStore = useRevealStore()
      if(eligibilityStore.revealing){
        // revealing_total.value = 1
        revealing_total.value = revealStore.getRevealedThisSession.length
        const reveal_success = await revealStore.revealTokens()
        if(reveal_success){
          setTimeout(() => {
            emit('next', 'YourTokens')
          }, 5000)
        } else {
          emit('terminate')
        }
      } else if(eligibilityStore.unlocking){
        console.debug('unlock_success')
        const purchaseStore = usePurchaseStore()
        const unlock_success = await purchaseStore.purchaseVersion()
        if(unlock_success){
          setTimeout(() => {
            emit('next', 'YourVersions')
          }, 5000)
        } else {
          emit('terminate')
        }
      } else{
        let isEligible
      if(userStore.getIsAWRD){
        isEligible = await eligibilityStore.loadTokenHoldingsAWRD()
      } else {
        isEligible = await eligibilityStore.loadTokenHoldingsWallet()
      }
      const purchaseStore = usePurchaseStore()
      console.debug("IS PURCHASE", purchaseStore.getIsPurchase)
      if(purchaseStore.getIsPurchase){
        console.debug("IS PURCHASE")
        const token_list = revealStore.getAllTokens
        const data = await purchaseStore.fetchProjectData(token_list)
        console.debug(data)
        emit('next', 'PurchaseSelect')
      }
      if(isEligible){
        setTimeout(() => {
          emit('next', 'SeriesSelect')
        }, 5000);

      } else {
        router.push('/thank-you')
      }
      }
    }
      handleDetails()

    return {
      checking,
      eligibilityStore,
      revealing_total
    }
  }
}
</script>
