<template>
  <div id="app">
    <GTM></GTM>
    <router-view />
  </div>
</template>

<script>
import GTM from './components/GTM'
import { onMounted } from 'vue';
export default {
  name: 'App',
  components: {
    GTM
  },
  setup(){
    onMounted(()=> {
      window.document.title = 'ZERG REVEALER'
      window.document.description = 'Reveal your ZERG Warriors with this simple Web App.'
    })
  }
}
</script>

<style>
.html{
  background-color: #0f0f0f;
}
</style>
