import { defineStore } from "pinia";

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        appKey: null,
        apiKey: null,
        isAWRD: null
    }),
    getters: {
        getAppKey: (state) =>{
            return state.appKey
        },
        getAPIKey: (state) => {
            return state.apiKey
        },
        getIsAWRD: (state) => {
            return state.isAWRD
        }
    },
    actions: {
        setUserData(appKey, apiKey) {
            this.appKey = appKey
            this.apiKey = apiKey
        },
        setIsAWRD(is_awrd){
            this.isAWRD = is_awrd
        }
    }
})