import { defineStore } from "pinia"
import { addresses as mint_addresses } from "@/assets/contracts/warriors.json";
import { useUserStore } from "./user"
import { useContractStore } from "./contract"
import { useRevealStore } from "./reveal";

export const useEligibilityStore = defineStore({
    id: 'eligibility',
    state: () => ({
        data: null,
        chain_id: 1,
        listSeedA: null,
        listSeedB: null,
        listSeedC: null,
        listSeedD: null,
        details: [],
        tokenIdRefList: [5925, 5926, 5927, 5928, 5929],
        tokenIdList: [],
        freeCount: 0,
        halfPricedCount: 0,
        eligible: null,
        no_talisman: false,
        skip: true,
        selectedBadgeDetails: [],
        burned_badges: {},
        revealing: false,
        unlocking: false
    }),
    getters: {
      getUnlocking(state){
        return state.unlocking
      },
        getEligibility(state) {
          return state.eligible
        },
        detailsGetter(state) {
            return state.details;
        },
        hasTokenId: (state) => (id) => {
            return state.tokenIdList.includes(id);
        },
        freeCountGetter(state) {
            return state.freeCount;
        },
        halfPricedCountGetter(state) {
            return state.halfPricedCount;
        },
        hasSelectedBadges(state) {
          return state.selectedBadgeDetails.length > 0;
        },
        getSelectedFree(state){
          return state.selectedBadgeDetails.filter(badge => badge.free);
        },
        getSelectedHalf(state){
            return state.selectedBadgeDetails.filter(badge => !badge.free);
        },
        getSelectedBadges(state){
          return state.selectedBadgeDetails
        },
    },
    actions: {
      setBadgeDetail(badgeDetail) {
        let badgeIndex = this.selectedBadgeDetails.findIndex(badge => badge.name === badgeDetail.name);
        if (badgeIndex !== -1) {
          let updatedBadge = { ...this.selectedBadgeDetails[badgeIndex], selected: this.selectedBadgeDetails[badgeIndex].selected + 1 };
          this.selectedBadgeDetails = [
            ...this.selectedBadgeDetails.slice(0, badgeIndex),
            updatedBadge,
            ...this.selectedBadgeDetails.slice(badgeIndex + 1)
          ];
        } else {
          this.selectedBadgeDetails.push({ ...badgeDetail, selected: 1 });
        }
      },
      setRevealing(value){
        this.revealing = value
      },
      setUnlocking(value){
        this.unlocking = value
      },
      setEligible(value) {
        this.eligible = value;
      },
      zeroOut: async function(){
        this.freeCount = 0
        this.halfPricedCount = 0
      },
      loadContractDetails: async function() {
        const contractStore = useContractStore()
        const userStore = useUserStore()
        const user_id = userStore.getAPIKey
      
        const totals = await contractStore.getMintsByAWRDID(user_id)
        let freeBalance = totals.list1
        let halfPricedBalance = totals.list2
        for(let detail of this.details){
          if(detail.burns && detail.burns > 0){
            this.burned_badges[detail.link.split('/').pop()] = {
              id: detail.link.split('/').pop(),
              burns: detail.burns,
              counts: detail.count,
              free: detail.free
            }
          }
        }
      
        for (let detail of this.details) {
          if (detail.free && freeBalance > 0) {
            let deductedAmount = detail.burns ? detail.burns : detail.count;
            if (deductedAmount <= freeBalance) {
              freeBalance -= deductedAmount
              detail.count -= deductedAmount
            } else {
              detail.count -= freeBalance
              freeBalance = 0
            }
          } else if (!detail.free && halfPricedBalance > 0) {
            let deductedAmount = detail.burns ? detail.burns : detail.count;
            if (deductedAmount <= halfPricedBalance) {
              halfPricedBalance -= deductedAmount
              detail.count -= deductedAmount
            } else {
              detail.count -= halfPricedBalance
              halfPricedBalance = 0
            }
          }
        }
        this.freeCount = this.freeCount - totals.list1
        this.halfPricedCount = this.halfPricedCount - totals.list2
        this.details = this.details.filter(detail => detail.count !== 0)
        if(this.skip){
          this.freeCount = 0
          this.halfPricedCount = 0
        }
      
      },
      loadTokenHoldingsWallet: async function(){
        const contractStore = useContractStore()
        const usersTokens = await contractStore.getTokensHeldByUser()
        const success = this.setHoldings(usersTokens)
        return success
      },
      loadTokenHoldingsAWRD: async function(){
        const userStore = useUserStore()
        const url = 'https://api.3gate.io/awardable/account/get_nfts'
        const contract = mint_addresses[this.chain_id]
        const network = this.chain_id === 1 ? 'mainnet' : 'goerli'
        const body = {
          API_TOKEN: userStore.apiKey,
          APP_KEY: userStore.appKey,
          contract: contract,
          network: network
        }
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (response.status === 401){
          this.eligible = false
          return false
        } else if (response.status === 500){
          this.eligible = false
          return false
        }
        const data = await response.json()
        this.data = data.body
        const success = this.setHoldings(this.data.tokens)
        return success

      },
      setHoldings(holdings){
        const revealStore = useRevealStore()
        if(holdings.length < 1){
          return false  
        } else {
          const tokensSet = revealStore.processAccountHoldings(holdings)
          return tokensSet
        }
      },
      
    findSeedObject(listSeed) {
      if (this.data && this.data.inputs) {
          return Object.values(this.data.inputs).find(seedObj => seedObj.list_seed === listSeed);
      }
      return null;
  }
    },
    setEligible(val){
      this.eligible = val
    }
})