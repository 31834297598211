import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { setupStore } from './store'
import './assets/css/normalize.css'
import './assets/css/webflow.css'
import './assets/css/zerg.webflow.css'
import './assets/css/normalize_2.css'
import './assets/css/webflow_2.css'
import './assets/css/zerg_2.webflow.css'
import './assets/css/normalize_1.css'
import './assets/css/webflow_1.css'
import './assets/css/zerg.webflow_1.css'
import utilities from './mixins/utilities.js'

const app = createApp(App)
app.mixin(utilities)
app.use(router)
setupStore(app)
app.mount('#app')
