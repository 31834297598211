export const SeriesData = Object.freeze({
    series_1: {
        name: 'I',
        image: '/images/sIsmall.gif',
        redirect_url: 'https://opensea.io/collection/zergwarriors?search[stringTraits][0][name]=Series&search[stringTraits][0][values][0]=I',
        count: 500,
        released: true,
        key: 'series_1',
        display_name: 'Series I'
    },
    series_2:{
        name: 'II',
        image: '/images/s2small.gif',
        redirect_url: 'https://opensea.io/collection/zergwarriors?search[stringTraits][0][name]=Series&search[stringTraits][0][values][0]=II',
        count: 500,
        released: true,
        key: 'series_2',
        display_name: 'Series II'
    },
    series_3:{
        name: 'III',
        image: '/images/s3small.gif',
        redirect_url: 'https://opensea.io/collection/zergwarriors?search[stringTraits][0][name]=Series&search[stringTraits][0][values][0]=III',
        count: 250,
        released: true,
        key: 'series_3',
        display_name: 'Series III'
    }
})