import { defineStore } from "pinia"
import { useUserStore } from "./user"
import { useContractStore } from "./contract"
import { useRevealStore } from "./reveal"


export const usePurchaseStore = defineStore({
    id: 'purchase',
    state: () => ({
        data: null,
        chain_id: 1,
        tokens: [],
        tokenIdList: [],
        series: {},
        total_revealed_tokens: {},
        total_sealed_tokens: {},
        tokens_with_version: [],
        tokens_without_version: [],
        selected_tokens: [],
        selected_ids: [],
        price: 2500,
        no_tokens: false,
        is_purchase: false,
        base_metadata_url: 'https://warriors-zerg.s3.amazonaws.com/warriors/metadata/warriors/',
        project_id: 18,
        version_number: '1.0.0',
        project_data: null,
        selected_customs: [],
        selected_framings: [],
        tools_hash: null,
        APP_KEY: null,
        revealed_tokens: [],
        selected_series: null,
        user_balance: 0
    }),
    getters: {
        getSelectedPrice(state){
            return state.price * state.selected_tokens.length
        },
        getSelectedThisSession(state){
            return state.revealed_tokens
        },
        getTokensForSeries: (state) => (seriesKey) => {
            return state.series[seriesKey]?.tokens || [];
        },
        getData(state){
            return state.data
        },
        getWithVersion(state){
            return state.tokens_with_version.length
        },
        getWithoutVersion(state){
            return state.tokens_without_version.length
        },
        getWithVersionBySeries: (state) => (seriesKey) =>{
            return state.tokens_with_version.filter(token => token.series === seriesKey)
        },
        getWithoutVersionBySeries: (state) => (seriesKey) =>{
            return state.tokens_without_version.filter(token => token.series === seriesKey)
        },
        getTokensForSelectedSeries(state){
            return state.tokens_without_version.filter(token => token.series === this.selected_series)
        },
        getSeriesSelected(state){
            return state.selected_series
        },
        getTokensWithVersion(state){
            return state.tokens_with_version
        },
        getTokensWithoutVersion(state){
            return state.tokens_without_version
        },
        getChainId(state){
            return state.chain_id
        },
        getTokens(state){
            return state.tokens
        },
        getTokenIds(state){
            return state.tokenIdList
        },
        getSeries(state){
            state.series
        },
        getHasTokens(state){
            state.no_tokens
        },
        getIsPurchase(state){
            return state.is_purchase
        },
        getProjectData(state){
            return state.project_data
        }
    },
    actions: {
        async purchaseVersion(){
            // const price = 1//this.price * this.selected_tokens.length
            // const endpoint_url = 'https://api.awardable.gg/badges/transfer_spend'
            // const userStore = useUserStore()
            // const APP_KEY = userStore.appKey
            // const API_TOKEN = userStore.apiKey
            // const API_KEY = 'gAAAAABlMEm_NpnAdYXOPlv0jWunWNG8gW3FVT8nV_1vr-Ss6q2iWW88U1s5rQfQtfuGPo6FPkfK_5mXFbXZL2PcUq6ADaTUktGHJFFejXCUUTOvsA8KsCE='
            // console.debug("VALIDATION DATA", APP_KEY, API_TOKEN)
            // const post_body = {
            //     // symbol: 'war',
            //     badge_id: 6259,
            //     badge_nb: price,
            //     API_TOKEN_FROM: API_TOKEN,
            //     // APP_KEY:APP_KEY,
            //     // APP_KEY: `${userStore.getAPIKey}:-:${userStore.getAppKey}`,
            //     API_TOKEN_TARGET: 22109,
            //     API_TOKEN: 22109,
            //     API_KEY: API_KEY
            //     // API_USERNAME_TARGET: 'WARRIORS'
            // }
            // const response = await fetch(endpoint_url, {
            //     method: 'POST',
            //     body: JSON.stringify(post_body)
            // })
            // const response_data = await response.json()
            // const body = response_data.body
            // console.debug('body',body)
            const set_success = await this.setVersions()
            return set_success
            // if(body.message === 'SUCCESS'){
            //     console.debug(body)
            // }

        },
        async setVersions(){
            const url = 'https://y5ql5opgp3.execute-api.us-east-1.amazonaws.com/default/setVersionLive'
            let token_ids = this.getSelectedThisSession.map(token => parseInt(token.token_id))
            let total_price = this.price * token_ids.length
            let body = {
                token_ids: token_ids,
                price: total_price
            }
            const userStore = useUserStore()
            body = {
                ...body,
                API_TOKEN_FROM: userStore.getAPIKey,
            }
            const response =  await fetch(url, {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
                body: JSON.stringify(body)
            })
            if(response.status !== 200){
                console.debug('ERROR REVEALING TOKENS')
                return false
            }
            const data = await response.json()
            const revealed_success = await this.updateRevealedTokens(data.tokens)
            return revealed_success
        },
        setIsPurchase(is_purchase){
            this.is_purchase = is_purchase
        },
        setProjectData(data){
            this.project_data = data
        },
        async fetchProjectData(token_list){
            // await this.purchaseVersion()
            this.tokenIdList = token_list
            // const endpoint_url = "https://71opdjh05j.execute-api.us-east-1.amazonaws.com/qNFT_project"
            // const post_body = {
                // id: this.project_id
            // }
            const userStore = useUserStore()
            const API_TOKEN = userStore.apiKey
            const endpoint_url = `https://api.awardable.gg/badges/get_all_user_tokens?type=owned&symbol=war&sort=newest&API_TOKEN=${API_TOKEN}`
            const holding_response = await fetch(endpoint_url, {
                method: 'GET'
            })
            const holding_data = await holding_response.json()
            if(holding_data.statusCode === 200){
                this.user_balance = holding_data.body.data[0].count
            }   
            const version_mod_url = "https://warriors-zerg.s3.amazonaws.com/warriors/versions/zombies.json"
            const response = await fetch(version_mod_url, {
                method: 'GET'
            })
            const response_data = await response.json()
            const body = response_data
            if(body){
                this.tokenIdList.forEach(token => {
                    if(token.revealed){
                        if(body.includes.includes(parseInt(token.token_id))){
                            this.tokens_with_version.push(token)
                        } else {
                            this.tokens_without_version.push(token)
                        }
                    }
                })
                // await this.generate_tools_hash(232)
                return true
            } else {
                return false
            }
        },
        async sign_typed_mesage(metadata, token_id) {
      const data = {
        title: `Save Metadata`,
        qNFT: {
          name: metadata.name,
          SKU: `018.${String(token_id).padStart(6, '0')}`,
        },
        metadata: {
          name: this.save_state_item_name ? this.save_state_item_name : ' ',
          description: this.item_description ? this.item_description : this.current_item_description ? this.current_item_description : ' ',
          state: this.is_metadata_reset ? this.project_data.default_state : this.item_data.version.value,
          customs: {
            names: [],
            images: []
            // names: !metadata.customs ? JSON.stringify([]) : JSON.stringify(metadata.customs.map((data) => data.name)),
            // images: !metadata.customs ? JSON.stringify([]) : JSON.stringify(metadata.customs.map((data) => data.image)),
          },
          framings: {
            names: [],// names: !metadata.customs ? JSON.stringify([]) : JSON.stringify(metadata.framings.map((data) => data.name)),
            classes: []// classes: !metadata.customs ? JSON.stringify([]) : JSON.stringify(metadata.framings.map((data) => data.class)),
          },
        },
      }
      const domain = {
        name: 'qNFT Tools',
        version: '1',
      }
      const types = {
        qNFT: [
          { name: 'name', type: 'string' },
          { name: 'SKU', type: 'string' },
        ],
        customs: [
          { name: 'names', type: 'string' },
          { name: 'images', type: 'string' },
        ],
        framings: [
          { name: 'names', type: 'string' },
          { name: 'classes', type: 'string' },
        ],
        metadata: [
          { name: 'name', type: 'string' },
          { name: 'description', type: 'string' },
          { name: 'state', type: 'string' },
          { name: 'customs', type: 'customs' },
          { name: 'framings', type: 'framings' },
        ],
        object: [
          { name: 'title', type: 'string' },
          { name: 'qNFT', type: 'qNFT' },
          { name: 'metadata', type: 'metadata' },
          { name: 'hash', type: 'string' },
          { name: 'timestamp', type: 'uint256' },
        ],
      }
      if (metadata.row_value) {
        data.metadata.row_value = String(metadata.row_value)
        types.metadata.push({ name: 'row_value', type: 'string' })
      }
      if (metadata.ctx_flip) {
        data.metadata.ctx_flip = String(metadata.ctx_flip)
        types.metadata.push({ name: 'ctx_flip', type: 'string' })
      }
      if (metadata.gif_delay_value) {
        data.metadata.gif_delay_value = metadata.gif_delay_value
        types.metadata.push({ name: 'gif_delay_value', type: 'uint256' })
      }
      const object = {
        title: data.title,
        qNFT: data.qNFT,
        metadata: data.metadata,
        hash: this.hash,
        timestamp: Date.now(),
      }
      try {
        // Add information needed by python web3
        types.EIP712Domain = [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
        ]
        const message = {
          domain,
          types,
          message: object,
          primaryType: 'object',
        }
        console.log('message:', message)
        return message
      } catch (error) {
        throw new Error(error.message)
      }
    },
    async generate_tools_hash(token_id) {
        const endpoint_url = 'https://pfz45nnnqh.execute-api.us-east-1.amazonaws.com/qNFT_hash_generate'
        const customs = []
        if (this.selected_customs) {
          Object.keys(this.selected_customs).forEach((key) => {
            const custom = this.selected_customs[key]
            customs.push({
              name: custom.name,
              image: custom.image.substring(custom.image.lastIndexOf('/') + 1),
            })
          })
        }
        const body = {
          collection: 'warriors',
          token_id: parseInt(token_id),
          customs: [],
          version: {
            description: '',
            image_ext: 'png',
            image_name: String(token_id),
            name: 'Zombie',
            value:this.version_number
        },
          framings: []
        }
        const response = await fetch(endpoint_url, {
            method: 'POST',
            body: JSON.stringify(body)
        })
        const response_data = await response.json()
        if(response_data.body.success){
            this.hash = response_data.body.hash
        }
      },
        async save_state_pressed() {
            try {
              const message = await this.sign_typed_mesage()
              const save_endpoint_url = 'https://k1p18z0dfc.execute-api.us-east-1.amazonaws.com/qNFT_save'
              const body = {
                message
              }
              this.save_state_button_text = 'Saving State...'
              await this.$axios.post(save_endpoint_url, body).then((response) => {
                console.log('response', response)
                const body = response.data.body
                if (body.success) {
                  console.log('success')
                  return true
                } else {
                  const error = new Error(body.message)
                  error.statusCode = body.statusCode
                  throw error
                }
              })
            } catch (error) {
              console.log(error)
              console.log(typeof error)
              if (typeof error == 'object') {
                try {
                  this.error_message = error.message
                } catch {
                  this.error_message = JSON.stringify(error)
                }
              } else {
                this.error_message = String(error)
              }
              if (error?.statusCode == 401) {
                this.is_item_owner = false
              }
              this.save_state_button_text = 'Save State'
            }
          },
        addRevealedToken(token){
            let updatedTokens = this.revealed_tokens
            updatedTokens.push(token)
            this.setRevealedTokens(updatedTokens)
        },
        addOrRemoveRevealedToken(token) {
            const updatedTokens = [...this.revealed_tokens];
            const tokenIndex = updatedTokens.findIndex((t) => t.token_id === token.token_id);
        
            if (tokenIndex === -1) {
                // Token is not present, so push it
                updatedTokens.push(token);
            } else {
                // Token is already present, so remove it
                updatedTokens.splice(tokenIndex, 1);
            }
        
            this.setRevealedTokens(updatedTokens);
        },
        setSelectedSeries(seriesKey){
            this.selected_series = seriesKey
        },
        setData(data){
            this.data = data
            return true
        },
        setTokens(tokens){
            const transformedTokens = Object.keys(tokens)
                .map(key => ({id: key, ...tokens[key]}))
                .map(token => ({
                    series: 'series_' + token.series || 1, 
                    display_series: 'Series ' + token.series,
                    image: token.revealed ? token.image_250 : `/images/s${token.series}small.gif`, 
                    display_image: token.image_250,
                    rarity: token.rarity,
                    revealed: token.revealed,
                    url: 'https://opensea.io/assets/ethereum/0x37dcb94bbe2d5a148beffeae1eba59e7508edafd/' + token.id, 
                    token_id: token.id,
                    display_id: token.id - (499 * (token.series -1)),
                    name: "WARRIOR " + token.id
                }));
            
            transformedTokens.forEach(token => {
                const seriesKey = `${token.series}`;
                if(!this.series[seriesKey]) {
                    this.series[seriesKey] = {
                        name: token.series,
                        tokens: []
                    };
                }
                this.series[seriesKey].tokens.push(token);
            });
            return true;
        },
        setRevealedTokens(tokens){
            this.revealed_tokens = tokens
            return true
        },
        setTokenIDList(token_list){
            this.tokenIdList = token_list
        },
        setNoTokens(has_tokens){
            this.no_tokens = has_tokens
        },
        setIsAWRD(is_awrd){
            this.is_awrd = is_awrd
        },
        setTotalRevealed(total_revealed){
            const revelaed_tokens = Object.keys(total_revealed)
            .filter(key => total_revealed[key].revealed !== false)
            .map(key => ({id:key, ...total_revealed[key]}))
            .map(key => ({
                series: 1, 
                name:'WARRIOR' + key, 
                image:'/images/worksample.jpg',
                id:key, 
                ...total_revealed[key]}))
            this.total_revealed_tokens = revelaed_tokens
            return true
        },
        setTotalSealed(total_sealed){
            const sealed_tokens = Object.keys(total_sealed)
            .filter(key => total_sealed[key].revealed !== true).map(key => ({id:key, ...total_sealed[key]}))
            .map(key => ({series: 1, name:'WARRIOR', image:'/images/s1small.gif', ...total_sealed[key]}))
            this.total_sealed_tokens = sealed_tokens
            return true
        },
        setSeriesRevealed(series_name){
            this.series_revealed = series_name
        },
        async processAccountHoldings(holdings){
            this.setTokenIDList(holdings)
            const url = "https://gs6qnixyf3.execute-api.us-east-1.amazonaws.com/zerg/get_reveal_status"
            const body = {
                token_ids: holdings,
            }
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            if(response.status !== 200){
                console.debug("ERROR GETTING TOKEN ID STATUS")
            }
            const data = await response.json()
        
            // Filter out tokens with series value not equal to 1
            // const filteredTokens = Object.fromEntries(
            //     Object.entries(data.body.tokens).filter(
            //         ([key, token]) => {
            //             console.debug('key', key)
            //             return token.series === 1
            //         }
            //     )
            // );
        
            // Updating the data object with filtered tokens
            // data.body.tokens = filteredTokens;
        
            const setData = this.setData(data);
            const setAll = this.setTokens(data.body.tokens);
            const setRevealed = this.setTotalRevealed(data.body.tokens);
            const setSealed = this.setTotalSealed(data.body.tokens);
        
            if (setData && setAll && setRevealed && setSealed) {
                return true;
            } else {
                console.debug('Error setting revealed data')
            }
        },        
        async revealTokens(){
            const url = 'https://gs6qnixyf3.execute-api.us-east-1.amazonaws.com/zerg/reveal'
            let token_ids = this.getRevealedThisSessionTokenIDs.map(id => parseInt(id))
            let body = {
                token_ids: token_ids
            }
            const userStore = useUserStore()
            const use_api = userStore.isAWRD
            if(use_api){
                body = {
                    ...body,
                    API_TOKEN: userStore.getAPIKey,
                    APP_KEY: userStore.getAppKey
                }
            } else {
                const contractStore = useContractStore()
                const { address, signature } = await contractStore.getSignature(token_ids)
                body = {
                    ...body,
                    wallet: address,
                    signature: signature
                }
            }
            const response =  await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            if(response.status !== 200){
                console.debug('ERROR REVEALING TOKENS')
                return false
            }
            const data = await response.json()
            const revealed_success = this.updateRevealedTokens(data.body.tokens)
            return revealed_success
        },
        async updateRevealedTokens(tokens){
            const updatedTokens = Object.keys(tokens)
            .map(key => ({id: key, ...tokens[key]}))
            .map(token => ({
                // series: 'Series ' + token.series, 
                image: token.image_250.replace('/images', ''), 
                rarity: token.rarity,
                revealed: token.revealed,
                url: 'https://opensea.io/assets/ethereum/0x37dcb94bbe2d5a148beffeae1eba59e7508edafd/' + token.id, 
                token_id: token.id,
                name: "WARRIOR " + token.id
            }));
            const revealStore = useRevealStore()
            this.series = revealStore.series
            this.total_revealed_tokens = revealStore.total_revealed_tokens
            this.total_sealed_tokens = revealStore.total_sealed_tokens
            
            updatedTokens.forEach(updatedToken => {
                const tokenIndex = this.series[this.selected_series]?.tokens.findIndex(token => token.token_id === updatedToken.token_id)
                if (tokenIndex !== -1) this.series[this.selected_series].tokens[tokenIndex] = updatedToken
        
                const revealedIndex = this.revealed_tokens.findIndex(token => token.token_id === updatedToken.token_id)
                if (revealedIndex !== -1) this.revealed_tokens[revealedIndex] = updatedToken
        
                const totalRevealedIndex = this.total_revealed_tokens.findIndex(token => token.token_id === updatedToken.token_id)
                if (totalRevealedIndex !== -1) this.total_revealed_tokens[totalRevealedIndex] = updatedToken
        
                const totalSealedIndex = this.total_sealed_tokens.findIndex(token => token.token_id === updatedToken.token_id)
                if (totalSealedIndex !== -1) this.total_sealed_tokens[totalSealedIndex] = updatedToken
                
                // Object.values(this.series_tokens_test).forEach(series => {
                //     const seriesIndex = series.tokens.findIndex(token => token.token_id === updatedToken.token_id)
                //     if (seriesIndex !== -1) series.tokens[seriesIndex] = updatedToken
                // })
            })
            
            return true
        }
        
        // async updateRevealedTokens(tokens){
        //     console.debug(tokens)
        //     const updatedTokens = Object.keys(tokens)
        //     .map(key => ({id: key, ...tokens[key]}))
        //     .map(token => ({
        //         series: 'Series ' + 1, 
        //         image: token.image, 
        //         rarity: "Common",
        //         revealed: token.revealed,
        //         url: 'https://opensea.io/assets/ethereum/0x37dcb94bbe2d5a148beffeae1eba59e7508edafd/' + token.id, 
        //         token_id: token.id,
        //         name: "WARRIOR " + token.id
        //     }))
        //     this.revealed_tokens = updatedTokens
        //     this.total_revealed_tokens = updatedTokens.filter(token => token.revealed)
        //     this.total_sealed_tokens = updatedTokens.filter(token => !token.revealed)

        //     return true
        // }
    },
})